import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ConfigurationModel } from '../../model/SynchronizationReportModel';

type Props = {
    configuration: ConfigurationModel;
};

export const ConfigurationReportContent = ({ configuration }: Props) => {
    const getConfigurationContent = () => {
        let content = [];

        if (configuration.with_medias) {
            content.push(
                <FormattedMessage
                    key='medias'
                    id='s1aoBI'
                    defaultMessage='Medias'
                />,
            );
        }
        if (configuration.with_metafields || configuration.with_associations) {
            content.push(
                <FormattedMessage
                    key='metafields'
                    id='i1xTYg'
                    defaultMessage='Metafields'
                />,
            );
        }
        if (configuration.with_published_products_in_channels) {
            content.push(
                <FormattedMessage
                    key='published_products'
                    defaultMessage='Publish products'
                    id='3oi/dq'
                />,
            );
        }
        return content;
    };

    return (
        <>
            {getConfigurationContent().map((content, index) => (
                <React.Fragment key={index}>
                    {content}
                    {index < getConfigurationContent().length - 1 && ', '}
                </React.Fragment>
            ))}
        </>
    );
};
