import { MessageDescriptor } from '@formatjs/intl/src/types';

const pimAttributeTypeMessagesToFormat: Record<string, MessageDescriptor> = {
    pim_catalog_identifier: {
        id: 'QRLVu/',
        defaultMessage: 'identifier',
    },
    pim_catalog_metric: {
        id: 'bTbyJe',
        defaultMessage: 'measurement',
    },
    pim_catalog_number: {
        id: '/kEE6/',
        defaultMessage: 'number',
    },
    pim_catalog_price_collection: {
        id: 'L3kuII',
        defaultMessage: 'price',
    },
    pim_catalog_simpleselect: {
        id: 'f0+2OR',
        defaultMessage: 'simple select',
    },
    pim_catalog_multiselect: {
        id: 'L5tG3H',
        defaultMessage: 'multi select',
    },
    pim_catalog_text: {
        id: '6vLBJ0',
        defaultMessage: 'text',
    },
    pim_catalog_textarea: {
        id: 'C83WpO',
        defaultMessage: 'text area',
    },
    pim_catalog_image: {
        id: '6zHQTa',
        defaultMessage: 'image',
    },
    pim_catalog_boolean: {
        id: '6zHQTa',
        defaultMessage: 'boolean',
    },
    pim_catalog_date: {
        id: 'TIeS2b',
        defaultMessage: 'date',
    },
    pim_catalog_table: {
        id: 'wGiurD',
        defaultMessage: 'table',
    },
    pim_catalog_file: {
        id: 'vwxMsy',
        defaultMessage: 'file',
    },
    pim_catalog_asset_collection: {
        id: '1yv5YX',
        defaultMessage: 'asset collection',
    },
};

export const selectPimAttributeTypeFormatted = (
    type: string,
): MessageDescriptor | null => pimAttributeTypeMessagesToFormat[type] ?? null;
