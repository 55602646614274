import { apiFetch } from '../../../util/apiFetch';

type Result = {
    localeOptions: { [code: string]: string };
    channelOptions: { [code: string]: string };
    currencyOptions: { [code: string]: string };
    error?: string;
};

export const getConnectorConfigOptions = (currentStoreId: string) =>
    apiFetch<Result>(
        `${currentStoreId}/configuration/retrieve-pim-config-options`,
    );
