import { apiFetch } from '../../../../util/apiFetch';

export type AssociationMappingByStore = {
    associationsMapped: string[];
    storeId: string;
};
export const saveMetafieldAssociationMapping = (
    associationsMapping: AssociationMappingByStore,
) =>
    apiFetch(
        `${associationsMapping.storeId}/metafield/save-metafield-association-mapping`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                associationsMapped: associationsMapping.associationsMapped,
            }),
        },
    );
