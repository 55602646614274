import { Collapse, Helper, Link, SectionTitle } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import { helpCenterProductPublishing } from '../../../util/config';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Skeleton } from '../../../component';
import { useSelector } from '../store/StoreProvider';
import { useCurrentStore } from '../../MultiStore';
import { usePublishingChannels } from './hook/usePublishingChannels';
import { SalesChannelsMultiSelect } from './component/SalesChannelsMultiSelect';
import { B2BCatalogsMultiSelect } from './component/B2BCatalogsMultiSelect';
import { InstabilityStickyHelper } from '../../../component/PageHeader/InstabilityStickyHelper';

export const PublishingSettings = () => {
    const { currentStoreId } = useCurrentStore();
    const [shopifyHasInstability, setShopifyHasInstability] =
        useState<boolean>(false);

    const bottomOfTheComponent = useRef<HTMLDivElement | null>(null);

    const [
        isProductPublishingSectionCollapsed,
        setIsProductPublishingSectionCollapsed,
    ] = useState<boolean>(true);

    const { connectorConfig } = useSelector((state) => ({
        connectorConfig: state.connectorConfigForm.data,
    }));

    const {
        data: publishingChannels,
        isError,
        isLoading,
    } = usePublishingChannels(currentStoreId, setShopifyHasInstability);

    const collapseOrExpandPreExistingCatalogMappingSection = () => {
        if (isProductPublishingSectionCollapsed) {
            setTimeout(() => {
                bottomOfTheComponent.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }, 200);
        }
        setIsProductPublishingSectionCollapsed(
            !isProductPublishingSectionCollapsed,
        );
    };

    useEffect(() => {
        if (
            connectorConfig?.publishingSettings &&
            (connectorConfig.publishingSettings.b2bCatalogs.length > 0 ||
                connectorConfig.publishingSettings.salesChannels.length > 0)
        ) {
            setIsProductPublishingSectionCollapsed(false);
        }
    }, [connectorConfig]);

    return (
        <StyledWrapper>
            {shopifyHasInstability && (
                <InstabilityStickyHelper source='SHOPIFY' />
            )}
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage
                        defaultMessage='Publishing settings (optional)'
                        id='2UD+J2'
                    />
                </SectionTitle.Title>
                <SectionTitle.Spacer />
            </SectionTitle>
            <ProductPublishingHelper level='info'>
                <FormattedMessage
                    defaultMessage='This section allows you to manage the sales channels and B2B catalogs where your products will be published.'
                    id='wMAaJA'
                />
                &nbsp;
                <Link href={helpCenterProductPublishing} target='_blank'>
                    <FormattedMessage
                        defaultMessage='Check out our Help Center to consult prerequisites and recommendations.'
                        id='NLW6os'
                    />
                </Link>
            </ProductPublishingHelper>
            <StyledCollapse
                collapseButtonLabel='Collapse'
                label=''
                isOpen={!isProductPublishingSectionCollapsed}
                onCollapse={() =>
                    collapseOrExpandPreExistingCatalogMappingSection()
                }
            >
                <StyledLayout>
                    {!isLoading && publishingChannels && !isError ? (
                        <>
                            <SalesChannelsMultiSelect
                                selectedPublishingChannel={[
                                    ...(connectorConfig?.publishingSettings.salesChannels.filter(
                                        (salesChannel) =>
                                            publishingChannels.some(
                                                (publishingChannel) =>
                                                    publishingChannel.publication_id ===
                                                    salesChannel.publication_id,
                                            ),
                                    ) ?? []),
                                ]}
                                publishingChannels={publishingChannels.filter(
                                    (publishingChannel) =>
                                        publishingChannel.type === 'APP',
                                )}
                            />
                            <B2BCatalogsMultiSelect
                                selectedPublishingChannel={[
                                    ...(connectorConfig?.publishingSettings.b2bCatalogs.filter(
                                        (b2bCatalog) =>
                                            publishingChannels.some(
                                                (publishingChannel) =>
                                                    publishingChannel.publication_id ===
                                                    b2bCatalog.publication_id,
                                            ),
                                    ) ?? []),
                                ]}
                                publishingChannels={publishingChannels.filter(
                                    (publishingChannel) =>
                                        publishingChannel.type ===
                                        'COMPANY_LOCATION',
                                )}
                            />
                        </>
                    ) : (
                        <>
                            <div>
                                <LabelSkeleton />
                                <FieldSkeleton />
                            </div>
                            <div>
                                <LabelSkeleton />
                                <FieldSkeleton />
                            </div>
                        </>
                    )}
                </StyledLayout>
                <div ref={bottomOfTheComponent}></div>
            </StyledCollapse>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    min-height: 120px;
`;

const ProductPublishingHelper = styled(Helper)`
    z-index: 1;
`;

const StyledCollapse = styled(Collapse)`
    border: none;
    margin-top: -88px;
    position: relative;
`;

const StyledLayout = styled.div`
    margin-top: 65px;
    display: flex;
    gap: 20px;
    flex-direction: column;
`;

const LabelSkeleton = styled(Skeleton)`
    height: 20px;
    width: 25%;
    margin-bottom: 10px;
`;

const FieldSkeleton = styled(Skeleton)`
    height: 40px;
`;
