import { apiFetch } from '../../../util/apiFetch';

type Result = {
    isConfigured: boolean;
};

export const isVariantPreExistingCatalogMappingConfigured = (
    currentStoreId: string,
) =>
    apiFetch<Result>(
        `${currentStoreId}/synchronization/is-variant-pre-existing-catalog-mapping-configured`,
    ).then((result) => {
        return result;
    });
