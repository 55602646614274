import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Button,
    Checkbox,
    CloseIcon,
    getColor,
    Helper,
    Link,
    Search,
    Table,
    useBooleanState,
} from 'akeneo-design-system';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { helpCenterAssociationsMappingUrl } from '../../../../util/config';
import { useCurrentStore } from '../../../MultiStore';
import { useQuery } from 'react-query';
import {
    getPimAssociations,
    getPimAssociationsQueryKey,
} from '../api/getPimAssociations';
import * as InstabilityType from '../../../../util/InstabilityType';
import { InstabilityStickyHelper } from '../../../../component/PageHeader/InstabilityStickyHelper';
import {
    Associations,
    AssociationType,
    DeletionListType,
} from '../model/MetafieldAssociationModel';
import { NoPimAssociation } from './NoPimAssociation';
import { NoPimAssociationInSearch } from './NoPimAssociationInSearch';
import { Loading } from '../../../../component';
import { SelectedFilter } from '../../Attributes/component/SelectedFilter';
import { useSaveMetafieldAssociationMapping } from '../useSaveMetafieldAssociationMapping';
import {
    getMetafieldAssociationMapping,
    getMetafieldAssociationMappingQueryKey,
} from '../api/getMetafieldAssociationMapping';
import { AssociationDeselectionModal } from './AssociationDeselectionModal';

const MAX_SELECTED_ASSOCIATIONS = 10;

const Container = styled.div`
    background: white;
    left: 0;
    padding: 40px 80px;
    position: absolute;
    top: 0;
    width: 100%;
`;

const ButtonContainer = styled.div`
    position: absolute;
    right: 80px;
    top: 40px;
`;

const ReturnButtonContainer = styled(Button)`
    margin-right: 10px;
    margin-bottom: 10px;
`;

const ButtonHelperContainer = styled(Helper)`
    padding-left: 40px;
`;

const Title = styled.h1`
    color: ${({ theme }) => theme.color.brand100};
    font-size: ${({ theme }) => theme.fontSize.bigger};
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
`;

const AttributeLabel = styled.div`
    color: ${({ theme }) => theme.color.grey140};
    font-size: ${({ theme }) => theme.fontSize.title};
    padding-top: 1rem;
    text-align: center;
`;

const CloseIconThemed = styled(CloseIcon)`
    position: absolute;
    left: 43.5px;
    top: 40px;
    color: ${getColor('brand', 100)};
    cursor: pointer;
`;

const HelperSelection = styled.div`
    margin-top: 19px;
    margin-bottom: 45px;
`;

const FixedWidthTableHeaderCell = styled(Table.HeaderCell)`
    width: 30%;
`;

const FixedWidthTableHeaderCellSelect = styled(Table.HeaderCell)`
    width: 50px;
    display: flex;
    align-items: center;
`;

const ColoredLabel = styled.span`
    color: ${({ theme }) => theme.color.brand100};
`;

const Space = styled(Table.HeaderCell)`
    width: 100%;
`;

const SpaceCell = styled(Table.Cell)`
    width: 100%;
`;

export const AssociationsSelection = () => {
    const navigate = useNavigate();
    const intl = useIntl();
    const { currentStoreId } = useCurrentStore();

    const { saveMapping, isSaving } = useSaveMetafieldAssociationMapping();
    const [isSelectionDirty, setSelectionDirty] = useState<boolean>(false);
    const [loading, setIsLoading] = useState(true);
    const [pimHasInstability, setPimHasInstability] = useState<boolean>(false);

    const [associations, setAssociations] = useState<Associations>([]);
    const [selectedMode, setSelectedMode] = useState('none');
    const [selectedAssociations, setSelectedAssociations] = useState<string[]>(
        [],
    );
    const [associationMapping, setAssociationMapping] = useState<string[]>([]);

    const [associationDeletionList, setAssociationDeletionList] =
        useState<DeletionListType>([]);

    useQuery(
        [
            currentStoreId,
            getPimAssociationsQueryKey,
            getMetafieldAssociationMappingQueryKey,
        ],
        () =>
            Promise.all([
                getPimAssociations(currentStoreId),
                getMetafieldAssociationMapping(currentStoreId),
            ]),
        {
            onError: () => {},
            onSuccess: ([
                { pimAssociations = [], error = null },
                { mapping },
            ]) => {
                setIsLoading(false);

                if (error && error === InstabilityType.pim_instability) {
                    setPimHasInstability(true);
                    return;
                }

                if (pimAssociations) {
                    setSelectedAssociations(mapping);
                    setAssociationMapping(mapping);
                    mapping.forEach((associationCode: string) => {
                        pimAssociations
                            .filter(
                                (association) =>
                                    association.code === associationCode,
                            )
                            .map(
                                (association) => (association.selected = true),
                            );
                    });

                    setSelectedMode(
                        mapping.length === pimAssociations.length
                            ? 'all'
                            : mapping.length === 0
                              ? 'none'
                              : 'mixed',
                    );
                }
                setAssociations(pimAssociations);
            },
        },
    );

    const checked = useMemo(() => {
        switch (selectedMode) {
            case 'none':
                return false;
            case 'all':
                return true;
            default:
                return 'mixed';
        }
    }, [selectedMode]);

    const selectAssociation = (
        currentAssociation: AssociationType,
        isChecked: boolean,
    ) => {
        if (isChecked) {
            associations
                .filter(
                    (association) =>
                        association.code === currentAssociation.code,
                )
                .map((association) => (association.selected = true));
            setSelectedAssociations([
                ...selectedAssociations,
                currentAssociation.code,
            ]);
            setSelectedMode(
                selectedAssociations.length + 1 === associations.length
                    ? 'all'
                    : 'mixed',
            );
        } else {
            associations
                .filter(
                    (association) =>
                        association.code === currentAssociation.code,
                )
                .map((association) => (association.selected = false));
            setSelectedAssociations(
                selectedAssociations.filter(
                    (association) => association !== currentAssociation.code,
                ),
            );
            setSelectedMode(
                selectedAssociations.length - 1 === 0 ? 'none' : 'mixed',
            );
        }
        setSelectionDirty(true);
    };

    const handleSelectAll = (checked: boolean) => {
        if (!checked) {
            setSelectionDirty(true);
        }
        if (!checked || (checked && maxAssociationsReached)) {
            setSelectedAssociations([]);
            setSelectedMode('none');
            associations.map((association) => (association.selected = false));
        } else {
            setSelectionDirty(true);
            if (associations.length > MAX_SELECTED_ASSOCIATIONS) {
                const remainingAssociation =
                    MAX_SELECTED_ASSOCIATIONS - selectedAssociations.length;
                const unSelecteAssociations = associations.filter(
                    (associations) => !associations.selected,
                );
                setSelectedAssociations([
                    ...selectedAssociations,
                    ...unSelecteAssociations
                        .slice(0, remainingAssociation)
                        .map((association) => association.code),
                ]);
                setSelectedMode('mixed');
                unSelecteAssociations
                    .slice(0, remainingAssociation)
                    .map((association) => (association.selected = true));
            } else {
                setSelectedAssociations(
                    associations.map((association) => association.code),
                );
                setSelectedMode('all');
                associations.map(
                    (association) => (association.selected = true),
                );
            }
        }
    };

    const [searchValue, setSearchValue] = useState('');
    const [selectedFilter, setSelectedFilter] = useState<
        'all' | 'selected' | 'unselected'
    >('all');
    const filteredAssociations = useMemo(() => {
        if (loading && !pimHasInstability) {
            return [];
        }

        return associations.filter((association) => {
            const { code, label } = association;

            const inFilter =
                (selectedFilter === 'selected' && association.selected) ||
                (selectedFilter === 'unselected' && !association.selected) ||
                selectedFilter === 'all';

            if (searchValue === '') {
                return inFilter;
            }

            const inSearch =
                code
                    .toString()
                    .toUpperCase()
                    .includes(searchValue.toUpperCase()) ||
                label
                    .toString()
                    .toUpperCase()
                    .includes(searchValue.toUpperCase());

            return inSearch && inFilter;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        associations,
        selectedAssociations,
        loading,
        pimHasInstability,
        searchValue,
        selectedFilter,
    ]);

    const maxAssociationsReached =
        selectedAssociations.length >= MAX_SELECTED_ASSOCIATIONS;

    const [isModalOpen, openModal, closeModal] = useBooleanState(false);

    const alreadySavedAssociationUnselected = () => {
        let deleted = false;
        let deletionList: DeletionListType = [];
        associationMapping.forEach((associationCode: string) => {
            if (!selectedAssociations.includes(associationCode)) {
                deleted = true;
                deletionList.push({
                    label:
                        associations.find(
                            (association) =>
                                association.code === associationCode,
                        )?.label ?? '',
                    reasonBy: {
                        productLevel: 'Metafield deletion',
                        variantLevel: 'Metafield deletion',
                    },
                });
            }
        });

        setAssociationDeletionList(deletionList);
        return deleted;
    };

    const handleSave = async () => {
        if (alreadySavedAssociationUnselected()) {
            openModal();
        } else {
            await saveSelection();
        }
    };

    const saveSelection = async () => {
        await saveMapping({
            associationsMapped: selectedAssociations,
            storeId: currentStoreId,
        });
    };

    return (
        <Container>
            <ButtonContainer>
                <ReturnButtonContainer
                    level='tertiary'
                    onClick={() => navigate('..')}
                    disabled={isSaving}
                >
                    <FormattedMessage id='0WJNP/' defaultMessage='Return' />
                </ReturnButtonContainer>
                <Button
                    level='primary'
                    disabled={!isSelectionDirty || isSaving}
                    onClick={handleSave}
                >
                    <FormattedMessage
                        id='b5nahW'
                        defaultMessage='Save selection'
                    />
                </Button>
                {isSelectionDirty && (
                    <ButtonHelperContainer inline level='warning'>
                        <FormattedMessage
                            id='0hzJRW'
                            defaultMessage='There are unsaved changes.'
                        />
                    </ButtonHelperContainer>
                )}
            </ButtonContainer>

            <Title>
                <FormattedMessage
                    id='XA8hA/'
                    defaultMessage='Metafields - PIM MetafieldAssociations'
                />
                <br />
            </Title>
            <AttributeLabel>
                <FormattedMessage id='xLigG0' defaultMessage='Selection' />
            </AttributeLabel>
            <CloseIconThemed size={16} onClick={() => navigate('..')} />

            <HelperSelection>
                <Helper level='info'>
                    <FormattedMessage
                        id='LB2iEi'
                        defaultMessage='Please note that you can only select {maxAssociations} associations. Each association will create 4 metafield definitions in Shopify. Associations with quantities are not supported.'
                        values={{ maxAssociations: MAX_SELECTED_ASSOCIATIONS }}
                    />
                    <br />
                    <Link
                        href={helpCenterAssociationsMappingUrl}
                        target='_blank'
                    >
                        <FormattedMessage
                            id='9R426o'
                            defaultMessage='Please check our Help Center for more information.'
                        />
                    </Link>
                </Helper>
            </HelperSelection>
            <Search
                onSearchChange={setSearchValue}
                placeholder={intl.formatMessage({
                    defaultMessage: 'Search',
                    id: 'xmcVZ0',
                })}
                searchValue={searchValue}
            >
                <div>
                    <ColoredLabel>
                        {intl.formatMessage(
                            {
                                defaultMessage:
                                    '{count, plural, =0 {# results} one {# result} other {# results} }',
                                id: 'zHNpm9',
                            },
                            { count: filteredAssociations.length },
                        )}
                    </ColoredLabel>
                </div>
                <SelectedFilter
                    value={selectedFilter}
                    onChange={setSelectedFilter}
                />
            </Search>

            {!pimHasInstability && !loading ? (
                filteredAssociations.length > 0 ? (
                    <>
                        <Table>
                            <Table.Header>
                                <FixedWidthTableHeaderCellSelect>
                                    <Checkbox
                                        checked={checked || false}
                                        onChange={handleSelectAll}
                                    ></Checkbox>
                                </FixedWidthTableHeaderCellSelect>
                                <FixedWidthTableHeaderCell>
                                    <FormattedMessage
                                        id='753yX5'
                                        defaultMessage='Label'
                                    />
                                </FixedWidthTableHeaderCell>
                                <FixedWidthTableHeaderCell>
                                    <FormattedMessage
                                        id='h2vipu'
                                        defaultMessage='Code'
                                    />
                                </FixedWidthTableHeaderCell>
                                <FixedWidthTableHeaderCell>
                                    <FormattedMessage
                                        id='+U6ozc'
                                        defaultMessage='Type'
                                    />
                                </FixedWidthTableHeaderCell>
                                <Space />
                            </Table.Header>
                            <Table.Body>
                                {filteredAssociations.map((association) => (
                                    <Table.Row key={association.code}>
                                        <Table.Cell>
                                            <Checkbox
                                                checked={
                                                    association.selected ||
                                                    false
                                                }
                                                readOnly={
                                                    isSaving ||
                                                    (maxAssociationsReached &&
                                                        !association.selected)
                                                }
                                                onChange={(isChecked) => {
                                                    selectAssociation(
                                                        association,
                                                        isChecked,
                                                    );
                                                }}
                                            ></Checkbox>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <ColoredLabel>
                                                {association.label}
                                            </ColoredLabel>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {association.code}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {association.isTwoWay
                                                ? intl.formatMessage({
                                                      id: 'haj1HB',
                                                      defaultMessage: '2-Way',
                                                  })
                                                : intl.formatMessage({
                                                      id: 'avSOtM',
                                                      defaultMessage: '1-Way',
                                                  })}
                                        </Table.Cell>
                                        <SpaceCell />
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </>
                ) : searchValue === '' ? (
                    <NoPimAssociation />
                ) : (
                    <NoPimAssociationInSearch />
                )
            ) : pimHasInstability ? (
                <InstabilityStickyHelper source='PIM' />
            ) : (
                <Loading />
            )}
            {isModalOpen && (
                <AssociationDeselectionModal
                    closeModal={closeModal}
                    associationDeletionList={associationDeletionList}
                    save={saveSelection}
                />
            )}
        </Container>
    );
};
