import React from 'react';
import { useQuery } from 'react-query';
import { isSynchronizationRunning } from './api/isSynchronizationRunning';
import { useCurrentStore } from '../MultiStore/CurrentStoreContext';

type Props = {
    children: React.ReactNode;
    fallback: () => React.ReactNode;
};

export const RunningSynchronizationGuard = ({ children, fallback }: Props) => {
    const { currentStoreId } = useCurrentStore();

    const isRunningSynchroQuery = useQuery(
        'synchronization/is-a-synchronization-running',
        () => isSynchronizationRunning(currentStoreId),
    );

    const isSynchroRunning = isRunningSynchroQuery.data;

    if (isSynchroRunning) {
        return <>{fallback()}</>;
    }

    return <>{children}</>;
};
