import { apiFetch } from '../../../util/apiFetch';

type Result = {
    isEmpty: boolean;
};

export const isSecondaryLocalesMappingEmpty = (currentStoreId: string) =>
    apiFetch<Result>(
        `${currentStoreId}/synchronization/is-secondary-locales-mapping-empty`,
    ).then((result) => result);
