import React from 'react';
import { Action, initialState, State } from './reducer';

export const StateContext = React.createContext<State | undefined>(
    initialState,
);

export const DispatchContext = React.createContext<
    React.Dispatch<Action> | undefined
>(undefined);

export const useSelector = <T extends unknown>(
    selector: (state: State) => T,
) => {
    const state = React.useContext(StateContext);
    if (state === undefined) {
        throw new Error();
    }

    return selector(state);
};

export const useDispatch = () => {
    const dispatch = React.useContext(DispatchContext);

    if (dispatch === undefined) {
        throw new Error();
    }

    return dispatch;
};
