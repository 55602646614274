import {
    AddingValueIllustration,
    Button,
    Information,
    Link,
    Table,
    SectionTitle,
} from 'akeneo-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { getStores } from '../api/getStores';
import React from 'react';
import { NoStore } from './NoStore';
import { StoreLine } from './StoreLine';
import { useDeleteStore } from '../hook/useDeleteStore';
import { retrieveStoreWithSynchronizationRunning } from '../api/retrieveStoresWithSynchronizationRunning';
import { Loading } from '../../../component';
import {
    helpCenterMultiStoreUrl,
    shopifyAppStoreUrl,
} from '../../../util/config';
import styled from 'styled-components';
import { FeatureGuard } from '../../../util/featureFlag';

const MAX_STORE = 100;

const NewStoreContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

const SectionTitleContainer = styled.div`
    margin-top: 12px;
    margin-bottom: 12px;
`;

const sectionTitleStyle = {
    overflow: 'visible',
};

export const StoresSelection = () => {
    const intl = useIntl();

    const {
        isLoading,
        data: storeList,
        refetch: storeListRefetch,
    } = useQuery({
        queryKey: 'store',
        queryFn: getStores,
        staleTime: 5000,
    });

    const { deleteStore } = useDeleteStore();

    const { data: storesWithSynchronizationRunning } = useQuery(
        'storesWithSynchronizationRunning',
        retrieveStoreWithSynchronizationRunning,
        {
            refetchInterval: 5000,
        },
    );

    const handleRedirectShopifyAppStore = () => {
        window.location.replace(shopifyAppStoreUrl);
    };

    const handleStoreDeletion = async (storeId: string) => {
        await deleteStore(storeId);
        await storeListRefetch();
    };

    if (isLoading) {
        return <Loading></Loading>;
    }

    if (storeList?.length === 0) {
        return <NoStore />;
    }

    return (
        <>
            <Information
                illustration={<AddingValueIllustration />}
                title={intl.formatMessage({
                    defaultMessage:
                        'Connect a single PIM with multiple Shopify stores',
                    id: 'R+XH0p',
                })}
            >
                <FormattedMessage
                    id='LzVar5'
                    defaultMessage='Multi-store feature offers you the ability to synchronize your PIM with multiple Shopify stores.'
                />
                <br />
                <Link href={helpCenterMultiStoreUrl} target='_blank'>
                    <FormattedMessage
                        id='5q2nke'
                        defaultMessage='Check out our Help Center for more information'
                    />
                </Link>
            </Information>
            <SectionTitleContainer>
                <SectionTitle>
                    <SectionTitle.Title style={sectionTitleStyle}>
                        <FormattedMessage
                            id='mr921C'
                            defaultMessage='STORE LIST'
                        />
                    </SectionTitle.Title>
                    <FeatureGuard feature='add_store'>
                        <NewStoreContainer>
                            <Button
                                level='primary'
                                onClick={handleRedirectShopifyAppStore}
                                disabled={
                                    storeList && storeList.length >= MAX_STORE
                                }
                            >
                                <FormattedMessage
                                    id='M9c19U'
                                    defaultMessage='Connect a new store'
                                />
                            </Button>
                        </NewStoreContainer>
                    </FeatureGuard>
                </SectionTitle>
            </SectionTitleContainer>
            <Table>
                <Table.Header>
                    <Table.HeaderCell>
                        <FormattedMessage
                            id='IUqPi4'
                            defaultMessage='Store name
                            '
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage
                            id='1f7SOL'
                            defaultMessage='Connection status'
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage
                            id='vy4ite'
                            defaultMessage='Last synchronization'
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage
                            id='JJJIez'
                            defaultMessage='Last synchronization status'
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell />
                </Table.Header>
                <Table.Body>
                    {storeList?.map((store) => (
                        <StoreLine
                            store={store}
                            key={store.id}
                            handleStoreDeletion={handleStoreDeletion}
                            hasSynchronizationInProgress={
                                storesWithSynchronizationRunning?.storeIds.includes(
                                    store.id,
                                ) ?? false
                            }
                        />
                    ))}
                </Table.Body>
            </Table>
        </>
    );
};
