import { useQuery } from 'react-query';
import { getConnectorConfig } from '../api/getConnectorConfig';
import { useCurrentStore } from '../../MultiStore/CurrentStoreContext';

export const getConnectorConfigQueryKey = 'Configuration/getConnectorConfig';

export const useGetConnectorConfig = () => {
    const { currentStoreId } = useCurrentStore();

    return useQuery(getConnectorConfigQueryKey, () =>
        getConnectorConfig(currentStoreId),
    );
};
