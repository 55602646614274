import { apiFetch } from '../../../util/apiFetch';

type Result = {
    isValid: boolean;
    isPimEnabled: boolean;
};

export const isValidPim = (pimURL: string) => {
    return apiFetch<Result>(
        `authentication/is-valid-pim?pim_url=${pimURL}`,
    ).then((result: Result) => result);
};
