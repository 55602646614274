import { apiFetch } from '../../../../util/apiFetch';

export type Result = {
    shopifyAttributes: {
        [code: string]: {
            code: string;
            collection: boolean;
            type: string;
            label: string;
            constraint: string;
            pimAllowedTypes: string[];
            isRequired: boolean;
        };
    };
    shopifyAttributeSections: {
        [label: string]: string[];
    };
};

export const getShopifyAttributes = (currentStoreId: string) =>
    apiFetch<Result>(
        `${currentStoreId}/mapping/retrieve-shopify-attributes`,
    ).then((result) => result);
