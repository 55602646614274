import { apiFetch } from '../../../util/apiFetch';
import { SynchronizationReport } from '../model/SynchronizationReportModel';

export const getSynchronizationReport = (
    storeId: string,
    synchronizationId: string,
) =>
    apiFetch<SynchronizationReport>(
        `${storeId}/synchronization/${synchronizationId}/retrieve-synchronization-report`,
    ).then((result) => {
        return result;
    });
