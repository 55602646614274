import { castDraft, Immutable, produce } from 'immer';
import { AttributeMapping } from '../../model/AttributeMapping';
import { ShopifyAttribute } from '../../model/ShopifyAttribute';
import { MappingStatus } from '../../model/MappingStatus';
import { PimAttributeMap } from '../../model/PimAttributeMap';
import { PimFamilyMap } from '../../model/PimFamilyMap';
import { PimAttributeErrors } from '../../model/PimAttributeErrors';

type ShopifyAttributeMap = { [shopifyAttributeCode: string]: ShopifyAttribute };
type ShopifySectionMap = { [shopifySectionLabel: string]: string[] };

export type State = Immutable<{
    shopifyAttributes: ShopifyAttributeMap;
    shopifyAttributeSections: ShopifySectionMap;
    pimAttributes: PimAttributeMap;
    pimFamilies: PimFamilyMap;
    mapping: Map<string, AttributeMapping>;
    mappingIsDirty: boolean;
    pimAttributeErrors: PimAttributeErrors | null;
}>;

export const initialState: State = {
    shopifyAttributes: {},
    shopifyAttributeSections: {},
    pimAttributes: {},
    pimFamilies: {},
    mapping: new Map(),
    mappingIsDirty: false,
    pimAttributeErrors: null,
};

export type Action =
    | {
          type: 'fetchAllData/fulfilled';
          shopifyAttributes: ShopifyAttributeMap;
          shopifyAttributeSections: ShopifySectionMap;
          pimAttributes: PimAttributeMap;
          pimFamilies: PimFamilyMap;
          mapping: {
              [shopifyAttributeCode: string]:
                  | {
                        shopifyAttributeCode: string;
                        hasAttributePerFamily: false;
                        hasCollectionOfAttribute: false;
                        data: string;
                    }
                  | {
                        shopifyAttributeCode: string;
                        hasAttributePerFamily: true;
                        hasCollectionOfAttribute: false;
                        data: { [pimFamilyCode: string]: string | null };
                    }
                  | {
                        shopifyAttributeCode: string;
                        hasAttributePerFamily: false;
                        hasCollectionOfAttribute: true;
                        data: string[];
                    }
                  | {
                        shopifyAttributeCode: string;
                        hasAttributePerFamily: true;
                        hasCollectionOfAttribute: true;
                        data: { [pimFamilyCode: string]: string[] | null };
                    };
          };
      }
    | {
          type: 'saveMapping/fulfilled';
      }
    | {
          type: 'saveMapping/rejected';
          pimAttributeErrors: PimAttributeErrors;
      }
    | {
          type: 'mapping/PimAttributeCodeChanged';
          shopifyAttributeCode: string;
          pimAttributeCode: string | null;
      }
    | {
          type: 'mapping/PimAttributeCodesChanged';
          shopifyAttributeCode: string;
          pimAttributeCodes: string[];
      }
    | {
          type: 'mapping/PimAttributeCodePerFamilyChanged';
          shopifyAttributeCode: string;
          pimFamilyCode: string;
          pimAttributeCode: string | null;
      }
    | {
          type: 'mapping/PimAttributeCodesPerFamilyChanged';
          shopifyAttributeCode: string;
          pimFamilyCode: string;
          pimAttributeCodes: string[];
      }
    | {
          type: 'mapping/HasAttributePerFamilyChanged';
          shopifyAttributeCode: string;
          hasAttributePerFamily: boolean;
      };

export const reducer = produce<(draft: State, action: Action) => State>(
    (draft, action) => {
        switch (action.type) {
            case 'fetchAllData/fulfilled':
                draft.shopifyAttributeSections = castDraft(
                    action.shopifyAttributeSections,
                );
                draft.shopifyAttributes = castDraft(action.shopifyAttributes);
                draft.pimAttributes = action.pimAttributes;
                draft.pimFamilies = action.pimFamilies;
                draft.mapping = new Map();
                Object.values(action.mapping).forEach((attributeMapping) => {
                    if (attributeMapping.hasAttributePerFamily) {
                        const familyMappingStatus =
                            Object.keys({ ...attributeMapping.data }).length ===
                            Object.keys(draft.pimFamilies).length
                                ? MappingStatus.Complete
                                : MappingStatus.InProgress;

                        if (attributeMapping.hasCollectionOfAttribute) {
                            draft.mapping.set(
                                attributeMapping.shopifyAttributeCode,
                                {
                                    shopifyAttributeCode:
                                        attributeMapping.shopifyAttributeCode,
                                    hasAttributePerFamily:
                                        attributeMapping.hasAttributePerFamily,
                                    hasCollectionOfAttribute:
                                        attributeMapping.hasCollectionOfAttribute,
                                    pimAttributeCodesPerFamily:
                                        attributeMapping.data,
                                    status: familyMappingStatus,
                                },
                            );
                        } else {
                            draft.mapping.set(
                                attributeMapping.shopifyAttributeCode,
                                {
                                    shopifyAttributeCode:
                                        attributeMapping.shopifyAttributeCode,
                                    hasAttributePerFamily:
                                        attributeMapping.hasAttributePerFamily,
                                    hasCollectionOfAttribute:
                                        attributeMapping.hasCollectionOfAttribute,
                                    pimAttributeCodePerFamily:
                                        attributeMapping.data,
                                    status: familyMappingStatus,
                                },
                            );
                        }
                    } else {
                        if (attributeMapping.hasCollectionOfAttribute) {
                            draft.mapping.set(
                                attributeMapping.shopifyAttributeCode,
                                {
                                    shopifyAttributeCode:
                                        attributeMapping.shopifyAttributeCode,
                                    hasAttributePerFamily:
                                        attributeMapping.hasAttributePerFamily,
                                    hasCollectionOfAttribute:
                                        attributeMapping.hasCollectionOfAttribute,
                                    pimAttributeCodes: attributeMapping.data,
                                    status: MappingStatus.Complete,
                                },
                            );
                        } else {
                            draft.mapping.set(
                                attributeMapping.shopifyAttributeCode,
                                {
                                    shopifyAttributeCode:
                                        attributeMapping.shopifyAttributeCode,
                                    hasAttributePerFamily:
                                        attributeMapping.hasAttributePerFamily,
                                    hasCollectionOfAttribute:
                                        attributeMapping.hasCollectionOfAttribute,
                                    pimAttributeCode: attributeMapping.data,
                                    status: MappingStatus.Complete,
                                },
                            );
                        }
                    }
                });
                draft.mappingIsDirty = false;
                break;

            case 'saveMapping/fulfilled':
                draft.mappingIsDirty = false;
                break;

            case 'mapping/PimAttributeCodeChanged':
                if (null !== action.pimAttributeCode) {
                    draft.mapping.set(action.shopifyAttributeCode, {
                        shopifyAttributeCode: action.shopifyAttributeCode,
                        hasCollectionOfAttribute: false,
                        hasAttributePerFamily: false,
                        pimAttributeCode: action.pimAttributeCode,
                        status: MappingStatus.Complete,
                    });
                } else {
                    draft.mapping.delete(action.shopifyAttributeCode);
                }
                draft.mappingIsDirty = true;
                break;

            case 'mapping/PimAttributeCodesChanged':
                if (action.pimAttributeCodes.length !== 0) {
                    draft.mapping.set(action.shopifyAttributeCode, {
                        shopifyAttributeCode: action.shopifyAttributeCode,
                        hasCollectionOfAttribute: true,
                        hasAttributePerFamily: false,
                        pimAttributeCodes: action.pimAttributeCodes,
                        status: MappingStatus.Complete,
                    });
                } else {
                    draft.mapping.delete(action.shopifyAttributeCode);
                }
                draft.mappingIsDirty = true;
                break;

            case 'mapping/HasAttributePerFamilyChanged':
                if (true === action.hasAttributePerFamily) {
                    const attributeMapping = draft.mapping.get(
                        action.shopifyAttributeCode,
                    );

                    const shopifyAttribute =
                        draft.shopifyAttributes[action.shopifyAttributeCode];

                    if (
                        attributeMapping?.hasAttributePerFamily ||
                        shopifyAttribute === undefined
                    ) {
                        throw new Error();
                    }

                    if (shopifyAttribute.collection) {
                        let pimAttributeCodesPerFamily = {};
                        draft.mapping.set(action.shopifyAttributeCode, {
                            shopifyAttributeCode: action.shopifyAttributeCode,
                            hasCollectionOfAttribute: true,
                            hasAttributePerFamily: true,
                            pimAttributeCodesPerFamily,
                            status:
                                Object.keys(pimAttributeCodesPerFamily)
                                    .length ===
                                Object.keys(draft.pimFamilies).length
                                    ? MappingStatus.Complete
                                    : MappingStatus.InProgress,
                        });
                    } else {
                        let pimAttributeCodePerFamily = {};

                        draft.mapping.set(action.shopifyAttributeCode, {
                            shopifyAttributeCode: action.shopifyAttributeCode,
                            hasCollectionOfAttribute: false,
                            hasAttributePerFamily: true,
                            pimAttributeCodePerFamily,
                            status:
                                Object.keys(pimAttributeCodePerFamily)
                                    .length ===
                                Object.keys(draft.pimFamilies).length
                                    ? MappingStatus.Complete
                                    : MappingStatus.InProgress,
                        });
                    }
                } else {
                    draft.mapping.delete(action.shopifyAttributeCode);
                }
                draft.mappingIsDirty = true;
                break;

            case 'mapping/PimAttributeCodePerFamilyChanged':
                const attributeMapping = draft.mapping.get(
                    action.shopifyAttributeCode,
                ) || {
                    shopifyAttributeCode: action.shopifyAttributeCode,
                    hasAttributePerFamily: true,
                    hasCollectionOfAttribute: false,
                    pimAttributeCodePerFamily: {},
                    status: MappingStatus.InProgress,
                };

                if (
                    !attributeMapping.hasAttributePerFamily ||
                    attributeMapping.hasCollectionOfAttribute
                ) {
                    throw new Error();
                }

                if (null !== action.pimAttributeCode) {
                    attributeMapping.pimAttributeCodePerFamily[
                        action.pimFamilyCode
                    ] = action.pimAttributeCode;
                    const isFamilyMappingComplete =
                        Object.keys(attributeMapping.pimAttributeCodePerFamily)
                            .length === Object.keys(draft.pimFamilies).length;
                    attributeMapping.status = isFamilyMappingComplete
                        ? MappingStatus.Complete
                        : MappingStatus.InProgress;
                } else {
                    delete attributeMapping.pimAttributeCodePerFamily[
                        action.pimFamilyCode
                    ];
                    attributeMapping.status = MappingStatus.InProgress;
                }

                draft.mapping.set(
                    action.shopifyAttributeCode,
                    attributeMapping,
                );
                draft.mappingIsDirty = true;
                break;

            case 'mapping/PimAttributeCodesPerFamilyChanged':
                const familyAttributeMapping = draft.mapping.get(
                    action.shopifyAttributeCode,
                ) || {
                    shopifyAttributeCode: action.shopifyAttributeCode,
                    hasAttributePerFamily: true,
                    hasCollectionOfAttribute: true,
                    pimAttributeCodesPerFamily: {},
                    status: MappingStatus.InProgress,
                };

                if (
                    !familyAttributeMapping.hasAttributePerFamily ||
                    !familyAttributeMapping.hasCollectionOfAttribute
                ) {
                    throw new Error();
                }

                if (action.pimAttributeCodes.length !== 0) {
                    familyAttributeMapping.pimAttributeCodesPerFamily[
                        action.pimFamilyCode
                    ] = action.pimAttributeCodes;
                    const isFamilyMappingComplete =
                        Object.keys(
                            familyAttributeMapping.pimAttributeCodesPerFamily,
                        ).length === Object.keys(draft.pimFamilies).length;
                    familyAttributeMapping.status = isFamilyMappingComplete
                        ? MappingStatus.Complete
                        : MappingStatus.InProgress;
                } else {
                    delete familyAttributeMapping.pimAttributeCodesPerFamily[
                        action.pimFamilyCode
                    ];
                    familyAttributeMapping.status = MappingStatus.InProgress;
                }

                draft.mapping.set(
                    action.shopifyAttributeCode,
                    familyAttributeMapping,
                );
                draft.mappingIsDirty = true;
                break;
            case 'saveMapping/rejected':
                // @ts-ignore
                draft.pimAttributeErrors = action.pimAttributeErrors;
                break;
        }

        return draft;
    },
);
