import { Immutable, produce } from 'immer';
import { MediaSource } from '../../../model/MediaSource';
import { ImageMapping } from '../model/ImageMapping';
import { AssetMapping, AssetType } from '../model/AssetMapping';
import { ImageAttribute } from '../model/ImageAttribute';
import { AssetCollectionAttributes } from '../model/AssetCollectionAttribute';
import { AssetAttributesPerAssetFamily } from '../model/AssetAttributePerAssetFamily';

export type State = Immutable<{
    mediaSource: MediaSource;
    mediaMapping: ImageMapping;
    assetMapping: AssetMapping;
    mediaAttributes: Map<string, ImageAttribute>;
    allowedAttributes: Map<string, ImageAttribute>;

    mediaAssetCollectionAttributes: Map<string, AssetCollectionAttributes>;
    allowedAssetCollections: Map<string, AssetCollectionAttributes>;
    mediaAssetAttributesPerAssetFamily: Map<
        string,
        AssetAttributesPerAssetFamily[]
    >;

    mappingIsDirty: boolean;
    mappingContext: string;
}>;

export const initialState: State = {
    mediaSource: MediaSource.Images,
    mediaMapping: [],
    assetMapping: [],
    mediaAttributes: new Map(),
    mediaAssetCollectionAttributes: new Map(),
    mediaAssetAttributesPerAssetFamily: new Map(),
    allowedAttributes: new Map(),
    allowedAssetCollections: new Map(),
    mappingIsDirty: false,
    mappingContext: '',
};

export type Action =
    | {
          type: 'setMappingContext';
          mappingContext: string;
      }
    | {
          type: 'mediaMapping/fetch/sourceAndMapping';
          mediaSource: MediaSource;
          mediaMapping: ImageMapping;
      }
    | {
          type: 'mediaMapping/fetch/attributes';
          mediaAttributes: ImageAttribute[];
      }
    | {
          type: 'mediaSource/source/changed';
          mediaSource: MediaSource;
      }
    | {
          type: 'mediaMapping/mapping/new';
          newAttribute: string;
      }
    | {
          type: 'mediaMapping/mapping/update';
          previousValue: string;
          newValue: string;
      }
    | {
          type: 'mediaSource/source/saved';
          mappingIsDirty: boolean;
      }
    | {
          type: 'mediaMapping/mapping/remove';
          attributeToRemove: string;
      }
    | {
          type: 'mediaMapping/mapping/clear';
      }
    | {
          type: 'mediaMapping/mapping/sort';
          newIndices: number[];
      }

    // Asset
    | {
          type: 'assetMapping/fetch/sourceAndMapping';
          mediaSource: MediaSource;
          assetMapping: AssetMapping;
      }
    | {
          type: 'assetMapping/fetch/collections';
          assetCollectionAttributes: AssetCollectionAttributes[];
      }
    | {
          type: 'assetMapping/fetch/attributes';
          assetAttributesPerAssetFamily: {
              [asset_family_code: string]: AssetAttributesPerAssetFamily[];
          };
      }
    | {
          type: 'assetMapping/mapping/new';
          newAttribute: string;
      }
    | {
          type: 'assetMapping/mapping/update';
          index: number;
          previousValue: string;
          newValue: string;
          newAssetAttribute: string | null;
      }
    | {
          type: 'assetMapping/mapping/remove';
          attributeToRemove: string;
      }
    | {
          type: 'assetMapping/mapping/clear';
      }
    | {
          type: 'assetMapping/mapping/sort';
          newIndices: number[];
      };

export const reducer = produce<(draft: State, action: Action) => State>(
    (draft, action) => {
        switch (action.type) {
            case 'setMappingContext':
                draft.mappingContext = action.mappingContext;
                break;
            // Media source
            case 'mediaSource/source/changed':
                if (draft.mediaSource === action.mediaSource) {
                    return;
                }

                draft.mediaSource = action.mediaSource;
                draft.mediaMapping = [];
                draft.assetMapping = [];
                draft.mappingIsDirty = true;
                break;

            case 'mediaSource/source/saved':
                draft.mappingIsDirty = action.mappingIsDirty;
                break;
            // Media mapping
            case 'mediaMapping/fetch/sourceAndMapping':
                draft.mediaSource = action.mediaSource;
                draft.mediaMapping = action.mediaMapping;
                draft.assetMapping = [];
                draft.mappingIsDirty = false;
                break;
            case 'mediaMapping/fetch/attributes':
                draft.mediaAttributes = new Map(
                    action.mediaAttributes.map((imageAttribute) => [
                        imageAttribute.code,
                        imageAttribute,
                    ]),
                );
                break;
            case 'mediaMapping/mapping/new':
                draft.mediaMapping.push(action.newAttribute);
                draft.mappingIsDirty = true;
                break;
            case 'mediaMapping/mapping/update':
                draft.mediaMapping = draft.mediaMapping.map((value) =>
                    value === action.previousValue ? action.newValue : value,
                );
                draft.mappingIsDirty = true;
                break;
            case 'mediaMapping/mapping/remove':
                draft.mediaMapping = draft.mediaMapping.filter(
                    (value) => value !== action.attributeToRemove,
                );
                draft.mappingIsDirty = true;
                break;
            case 'mediaMapping/mapping/clear':
                draft.mediaMapping = [];
                draft.mappingIsDirty = true;
                break;
            case 'mediaMapping/mapping/sort':
                const sortedMediaMapping: string[] = [];

                action.newIndices.forEach((newIndex: number) => {
                    const attribute: string | undefined =
                        draft.mediaMapping[newIndex];
                    if (attribute) {
                        sortedMediaMapping.push(attribute);
                    }
                });

                draft.mediaMapping = sortedMediaMapping;
                draft.mappingIsDirty = true;
                break;

            // Asset mapping
            case 'assetMapping/fetch/sourceAndMapping':
                draft.mediaSource = action.mediaSource;
                draft.assetMapping = action.assetMapping;
                draft.mediaMapping = [];
                draft.mappingIsDirty = false;
                break;
            case 'assetMapping/fetch/collections':
                draft.mediaAssetCollectionAttributes = new Map(
                    action.assetCollectionAttributes.map(
                        (assetCollectionAttributes) => [
                            assetCollectionAttributes.code,
                            assetCollectionAttributes,
                        ],
                    ),
                );
                break;
            case 'assetMapping/fetch/attributes':
                draft.mediaAssetAttributesPerAssetFamily = new Map(
                    Object.entries(action.assetAttributesPerAssetFamily).map(
                        ([assetFamilyCode, assetAttributes]) => [
                            assetFamilyCode,
                            assetAttributes,
                        ],
                    ),
                );
                break;
            case 'assetMapping/mapping/new':
                draft.assetMapping.push({
                    attribute_code: action.newAttribute,
                    asset_attribute_code: '',
                });

                draft.mappingIsDirty = false;
                break;
            case 'assetMapping/mapping/update':
                draft.assetMapping[action.index]!.attribute_code =
                    action.newValue;
                draft.assetMapping[action.index]!.asset_attribute_code =
                    action.newAssetAttribute ? action.newAssetAttribute : '';

                if (null !== action.newAssetAttribute) {
                    draft.mappingIsDirty = true;
                } else {
                    draft.mappingIsDirty = false;
                }
                break;
            case 'assetMapping/mapping/remove':
                draft.assetMapping = draft.assetMapping.filter(
                    (value) =>
                        value.attribute_code !== action.attributeToRemove,
                );
                draft.mappingIsDirty = true;
                break;
            case 'assetMapping/mapping/clear':
                draft.assetMapping = [];
                draft.mappingIsDirty = true;
                break;
            case 'assetMapping/mapping/sort':
                const sortedAssetMapping: AssetType[] = [];

                action.newIndices.forEach((newIndex: number) => {
                    const attribute: AssetType | undefined =
                        draft.assetMapping[newIndex];
                    if (attribute) {
                        sortedAssetMapping.push(attribute);
                    }
                });

                draft.assetMapping = sortedAssetMapping;
                draft.mappingIsDirty = true;
                break;
        }

        // Media mapping
        if (draft.mediaSource === MediaSource.Images) {
            draft.allowedAttributes = new Map(draft.mediaAttributes);
            draft.mediaMapping.forEach((attribute) =>
                draft.allowedAttributes.delete(attribute),
            );
        }

        // Asset mapping
        if (draft.mediaSource === MediaSource.Assets) {
            draft.allowedAssetCollections = new Map(
                draft.mediaAssetCollectionAttributes,
            );
            draft.assetMapping.forEach((asset) => {
                draft.allowedAssetCollections.delete(asset.attribute_code);
                if (!asset.asset_attribute_code) {
                    draft.mappingIsDirty = false;
                }
            });
        }

        return draft;
    },
);
