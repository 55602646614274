import React, { useEffect, useState } from 'react';
import { SynchronizationScope } from '../../model/SynchronizationScope';
import { Synchronization } from '../../model/Synchronization';
import { useToaster } from '../../../../util/toast';
import { useIntl } from 'react-intl';
import { useSelector } from '../../store/StoreProvider';
import { SynchronizationStatus } from '../../model/SynchronizationStatus';
import { ActivityIcon } from 'akeneo-design-system';
import { useQuery } from 'react-query';
import { fetchSynchronizationHistories } from '../../api/fetchSynchronizationHistories';
import { useCurrentStore } from '../../../MultiStore/CurrentStoreContext';

type SynchronizationMap = { [key in SynchronizationScope]: Synchronization };

const useNotifyFinishedSynchronization = () => {
    const addToast = useToaster();
    const intl = useIntl();

    const [previousSynchronizations, setPreviousSynchronizations] =
        useState<SynchronizationMap | null>(null);

    const synchronizations = useSelector((state) => state.synchronizations);

    const { currentStoreId } = useCurrentStore();

    const query = useQuery('fetchSynchronizationHistories', () =>
        fetchSynchronizationHistories(currentStoreId),
    );

    useEffect(() => {
        if (previousSynchronizations === null) {
            setPreviousSynchronizations(synchronizations);
            return;
        }

        if (
            isSynchronizationReady(previousSynchronizations, synchronizations)
        ) {
            if (
                isSynchronizationFinished(
                    previousSynchronizations,
                    synchronizations,
                )
            ) {
                // Notify if all synchronizations are finished
                addToast(
                    intl.formatMessage({
                        id: 'GLyjFZ',
                        defaultMessage: 'Synchronization finished',
                    }),
                    'info',
                    <ActivityIcon />,
                    intl.formatMessage({
                        defaultMessage:
                            'See the History section below to see the status of the synchronization.',
                        id: 'COifSF',
                    }),
                );
            }

            // Reload history if all synchronizations are ready
            query.refetch();
        }
        setPreviousSynchronizations(synchronizations);
    }, [previousSynchronizations, synchronizations, addToast, intl, query]);
};

const isSynchronizationReady = (
    previousSynchronizations: SynchronizationMap,
    synchronizations: SynchronizationMap,
) =>
    Object.values(previousSynchronizations).filter(
        (previousSynchronization) =>
            previousSynchronization.status === SynchronizationStatus.Disabled,
    ).length > 0 &&
    Object.values(synchronizations).filter(
        (synchronization) =>
            synchronization.status === SynchronizationStatus.Disabled,
    ).length === 0;

const isSynchronizationFinished = (
    previousSynchronizations: SynchronizationMap,
    synchronizations: SynchronizationMap,
) => {
    const startedSynchronizationStatus = [
        SynchronizationStatus.Pending,
        SynchronizationStatus.InProgress,
        SynchronizationStatus.Stopping,
    ];

    return (
        Object.values(previousSynchronizations).filter(
            (previousSynchronization) =>
                startedSynchronizationStatus.includes(
                    previousSynchronization.status,
                ),
        ).length > 0 &&
        Object.values(synchronizations).filter((synchronization) =>
            startedSynchronizationStatus.includes(synchronization.status),
        ).length === 0
    );
};

export { useNotifyFinishedSynchronization };
