import { ProgressBar } from 'akeneo-design-system';
import { useIntl } from 'react-intl';
import { selectAttributeMapping } from '../../selector/selectAttributeMapping';
import { useSelector } from '../../store/StoreProvider';

type Props = {
    shopifyAttributeCode: string;
};

export const MappedFamilyProgressBar = ({ shopifyAttributeCode }: Props) => {
    const intl = useIntl();

    const pimFamilyCount = useSelector(
        (state) => Object.keys(state.pimFamilies).length,
    );

    const mappedPimAttributePerFamilyCount = useSelector((state) => {
        const attributeMapping =
            selectAttributeMapping(shopifyAttributeCode)(state);
        if (!attributeMapping.hasAttributePerFamily) {
            throw new Error();
        }

        return Object.values(
            attributeMapping.hasCollectionOfAttribute
                ? attributeMapping.pimAttributeCodesPerFamily
                : attributeMapping.pimAttributeCodePerFamily,
        ).filter((pimAttributeCode) => null !== pimAttributeCode).length;
    });

    return (
        <ProgressBar
            level='primary'
            percent={(mappedPimAttributePerFamilyCount / pimFamilyCount) * 100}
            progressLabel={`${mappedPimAttributePerFamilyCount}/${pimFamilyCount}`}
            size='large'
            title={intl.formatMessage({
                id: '/lz/lz',
                defaultMessage: 'Families mapped',
            })}
        />
    );
};
