import { AllowedPimAttributeMultiselect } from '../component/AllowedPimAttributeMultiselect';
import { ShopifyAttribute } from '../../model/ShopifyAttribute';
import { selectAttributeMapping } from '../selector/selectAttributeMapping';
import { useDispatch, useSelector } from '../store/StoreProvider';

type Props = {
    shopifyAttribute: ShopifyAttribute;
};

export const PimAttributeCollectionSelector = ({ shopifyAttribute }: Props) => {
    const dispatch = useDispatch();

    const attributeMapping = useSelector((state) => {
        const attributeMapping = selectAttributeMapping(shopifyAttribute.code)(
            state,
        );
        if (!attributeMapping.hasCollectionOfAttribute) {
            throw new Error();
        }

        return attributeMapping;
    });

    const handleChange = (pimAttributeCodes: string[]) => {
        dispatch({
            type: 'mapping/PimAttributeCodesChanged',
            shopifyAttributeCode: shopifyAttribute.code,
            pimAttributeCodes,
        });
    };
    return (
        <>
            <AllowedPimAttributeMultiselect
                shopifyAttribute={shopifyAttribute}
                selectedPimAttributeCodes={
                    attributeMapping.hasAttributePerFamily
                        ? []
                        : (attributeMapping.pimAttributeCodes as string[])
                }
                disabled={attributeMapping.hasAttributePerFamily}
                onChange={handleChange}
            />
        </>
    );
};
