import { Helper, Link, Table } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from '../store/StoreProvider';
import { SecondaryLocalesItem } from './SecondaryLocalesItem';
import {
    helpCenterConfigurationUrl,
    helpCenterMultiLocaleManagement,
} from '../../../../util/config';

const Space = styled(Table.HeaderCell)`
    width: 45%;
`;
const ShopifyHelperContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const cellStyle = {
    background: '#f6f7fb',
    padding: 0,
};
const MAX_LOCALES = 10;

export const SelectSecondaryLocales = () => {
    const { secondaryLocalesMapping, secondaryLocalesMapped } = useSelector(
        (state) => state,
    );

    return (
        <>
            <Table>
                <Table.Header>
                    <Table.HeaderCell>
                        <FormattedMessage
                            id='MqL7R+'
                            defaultMessage='Shopify language'
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage
                            id='7KloSP'
                            defaultMessage='PIM locale'
                        />
                    </Table.HeaderCell>
                    <Space />
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell colSpan={4} style={cellStyle}>
                            <ShopifyHelperContainer>
                                <Helper level='info'>
                                    <FormattedMessage
                                        id='n4GVtM'
                                        defaultMessage='Secondary locales allow you to synchronize your translations into different Shopify languages, '
                                    />
                                    <Link
                                        href={helpCenterMultiLocaleManagement}
                                        target='_blank'
                                    >
                                        <FormattedMessage
                                            id='+z9ImG'
                                            defaultMessage='check out our Help Center for more information.'
                                        />
                                    </Link>
                                    <br />
                                    <FormattedMessage
                                        id='L/rz6J'
                                        defaultMessage='Please note that this can have a significant impact on performance and increase synchronization duration.'
                                    />
                                </Helper>
                            </ShopifyHelperContainer>
                        </Table.Cell>
                    </Table.Row>

                    {secondaryLocalesMapping.map((localesMapping, index) => {
                        if (secondaryLocalesMapped !== undefined) {
                            return (
                                <Table.Row key={index}>
                                    <SecondaryLocalesItem
                                        index={index}
                                        localesMapping={localesMapping}
                                    />
                                </Table.Row>
                            );
                        }
                        return <></>;
                    })}
                    {secondaryLocalesMapping.length !== MAX_LOCALES &&
                        secondaryLocalesMapping.slice(-1)[0]?.pimValue !==
                            null &&
                        secondaryLocalesMapping.slice(-1)[0]?.shopifyKey !==
                            null && (
                            <Table.Row key={'newAttribute'}>
                                <SecondaryLocalesItem
                                    index={null}
                                    localesMapping={null}
                                />
                            </Table.Row>
                        )}
                </Table.Body>
            </Table>
            {secondaryLocalesMapping.length === MAX_LOCALES &&
                secondaryLocalesMapping.slice(-1)[0]?.pimValue !== null &&
                secondaryLocalesMapping.slice(-1)[0]?.shopifyKey !== null && (
                    <Helper level='warning'>
                        <FormattedMessage
                            id='HiU08F'
                            values={{
                                maxSecondaryLocales: MAX_LOCALES,
                            }}
                            defaultMessage='You have reached the limit of {maxSecondaryLocales} secondary locales.'
                        />{' '}
                        <Link href={helpCenterConfigurationUrl} target='_blank'>
                            <FormattedMessage
                                id='i/qJsQ'
                                defaultMessage='Please check the Help Center for more information.'
                            />
                        </Link>
                    </Helper>
                )}
        </>
    );
};
