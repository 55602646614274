import { apiFetch } from '../../../../util/apiFetch';
import { ShopifyMetafieldDefinition } from '../model/MetafieldAttributeModel';

type Result = {
    productDefinitions: ShopifyMetafieldDefinition[];
    variantDefinitions: ShopifyMetafieldDefinition[];
    error?: string;
} | null;

export const getExistingShopifyMetafieldAttributeDefinitions = (
    storeId: string,
) =>
    apiFetch<Result>(
        `${storeId}/metafield/get-existing-shopify-metafield-attribute-definitions`,
    ).then((result) => result);
