import React from 'react';
import { AllowedPimAttributeSelect } from '../component/AllowedPimAttributeSelect';
import { ShopifyAttribute } from '../../model/ShopifyAttribute';
import { selectAttributeMapping } from '../selector/selectAttributeMapping';
import { useDispatch, useSelector } from '../store/StoreProvider';

type Props = {
    shopifyAttribute: ShopifyAttribute;
    pimFamilyCode: string;
};

export const FamilyPimAttributeSelector = ({
    shopifyAttribute,
    pimFamilyCode,
}: Props) => {
    const dispatch = useDispatch();

    const pimAttributeCode = useSelector((state) => {
        const attributeMapping = selectAttributeMapping(shopifyAttribute.code)(
            state,
        );
        if (!attributeMapping.hasAttributePerFamily) {
            throw new Error();
        }
        if (attributeMapping.hasCollectionOfAttribute) {
            throw new Error();
        }

        const attributeCode =
            attributeMapping.pimAttributeCodePerFamily[pimFamilyCode];
        if (!attributeCode) {
            return null;
        }

        return attributeCode;
    });

    const handleChange = (pimAttributeCode: string | null) =>
        dispatch({
            type: 'mapping/PimAttributeCodePerFamilyChanged',
            shopifyAttributeCode: shopifyAttribute.code,
            pimFamilyCode,
            pimAttributeCode,
        });

    return (
        <AllowedPimAttributeSelect
            shopifyAttribute={shopifyAttribute}
            selectedPimAttributeCode={pimAttributeCode}
            pimFamilyCode={pimFamilyCode}
            onChange={handleChange}
        />
    );
};
