import { Immutable } from 'immer';
import {
    isSaveConnectorConfigValidationError,
    saveConnectorConfig,
} from '../api/saveConnectorConfig';
import { ConnectorConfigForm } from '../model/ConnectorConfigForm';
import { useDispatch } from '../store/StoreProvider';
import { useCurrentStore } from '../../MultiStore';
import { connectorConfigDeepCopy } from '../store/reducer';

export const useSaveConnectorConfigForm = () => {
    const dispatch = useDispatch();

    const { currentStoreId } = useCurrentStore();

    return async (form: Immutable<ConnectorConfigForm>): Promise<boolean> => {
        if (null === form.data || !form.isDirty) {
            return false;
        }

        try {
            await saveConnectorConfig(
                connectorConfigDeepCopy(form.data),
                currentStoreId,
            );

            dispatch({ type: 'saveConnectorConfig/fulfilled' });

            return true;
        } catch (error) {
            if (!isSaveConnectorConfigValidationError(error)) {
                throw error;
            }

            dispatch({
                type: 'saveConnectorConfig/rejected',
                errors: error.data.errors,
            });

            return false;
        }
    };
};
